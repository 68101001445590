import {useState} from "react";
import _ from "lodash";
import {
    IoEarth, IoLogoBehance, IoLogoDiscord, IoLogoDribbble,
    IoLogoFacebook, IoLogoGithub,
    IoLogoInstagram,
    IoLogoLinkedin, IoLogoMedium, IoLogoPinterest, IoLogoReddit, IoLogoSnapchat, IoLogoTwitch,
    IoLogoTwitter, IoLogoVimeo, IoLogoWechat,
    IoLogoWhatsapp,
    IoLogoYoutube
} from "react-icons/io5";
import {FaCommentAlt, FaDailymotion, FaPeriscope, FaTelegramPlane, FaViber} from "react-icons/fa";
import {ImEllo, ImHangouts} from "react-icons/im";
import {SiSignal, SiSwarm, SiZoom} from "react-icons/si";
import {Image} from "@mantine/core";
import {BiMessage} from "react-icons/bi";


const social = [
    {
        id:1,
        slug:'website',
        icon:<IoEarth/>
    },

    {
        id:2,
        slug:'whatsapp',
        icon:<IoLogoWhatsapp/>
    },


    {
        id:3,
        slug:'instagram',
        icon:<IoLogoInstagram/>
    },

    {
        id:4,
        slug:'facebook',
        icon:<IoLogoFacebook/>
    },

    {
        id:5,
        slug:'twitter',
        icon:<IoLogoTwitter/>
    },

    {
        id:6,
        slug:'linkedin',
        icon:<IoLogoLinkedin/>
    },

    {
        id:7,
        slug:'youtube',
        icon:<IoLogoYoutube/>
    },

    {
        id:8,
        slug:'zoom',
        icon:<SiZoom className={"zoom"}/>
    },
    {
        id:9,
        slug:'telegram',
        icon:<FaTelegramPlane/>
    },

    {
        id:10,
        slug:'bip',
        icon:<Image alt={'bip'} src={'/img/icon/bip.svg'} className={"gray-icon"} width={15} height={15}/>
    },

    {
        id:11,
        slug:'discord',
        icon:<IoLogoDiscord/>
    },

    {
        id:12,
        slug:'dribble',
        icon:<IoLogoDribbble/>
    },

    {
        id:13,
        slug:'vimeo',
        icon:<IoLogoVimeo/>
    },

    {
        id:14,
        slug:'ello',
        icon:<ImEllo/>
    },

    {
        id:15,
        slug:'github',
        icon:<IoLogoGithub/>
    },
    {
        id:16,
        slug:'hangouts',
        icon:<ImHangouts/>
    },


    {
        id:17,
        slug:'icbc',
        icon:'Icbc'
    },

    {
        id:18,
        slug:'icq',
        icon:'Icq'
    },

    {
        id:19,
        slug:'kikmessenger',
        icon:'Kik'
    },

    {
        id:20,
        slug:'twitch',
        icon:<IoLogoTwitch/>
    },


    {
        id:21,
        slug:'medium',
        icon:<IoLogoMedium/>
    },


    {
        id:22,
        slug:'nimotv',
        icon:'NmTv'
    },


    {
        id:23,
        slug:'periscope',
        icon:<FaPeriscope/>
    },

    {
        id:24,
        slug:'dailymotion',
        icon:<FaDailymotion/>
    },


    {
        id:25,
        slug:'wechat',
        icon:<IoLogoWechat/>
    },

    {
        id:26,
        slug:'qqtile',
        icon:''
    },

    {
        id:27,
        slug:'sineweibo',
        icon:''
    },

    {
        id:28,
        slug:'dlive',
        icon:''
    },

    {
        id:29,
        slug:'pinterest',
        icon:<IoLogoPinterest/>
    },

    {
        id:30,
        slug:'reddit',
        icon:<IoLogoReddit/>
    },


    {
        id:31,
        slug:'behance',
        icon:<IoLogoBehance/>
    },

    {
        id:32,
        slug:'swarm',
        icon:<SiSwarm/>
    },

    {
        id:33,
        slug:'signal',
        icon:<SiSignal/>
    },

    {
        id:34,
        slug:'yaya',
        icon:''
    },


    {
        id:35,
        slug:'c2',
        icon:<FaCommentAlt/>
    },


    {
        id:36,
        slug:'tango',

        icon:''
    },

    {
        id:37,
        slug:'vero',
        icon:''
    },

    {
        id:38,
        slug:'viber',
        icon:<FaViber/>
    },


    {
        id:39,
        slug:'teams',
        icon:''
    },

    {
        id:40,
        slug:'snapchat',
        icon:<IoLogoSnapchat/>
    },

];

export const SocialIcon = (type = '') => {



    return _.find(social, {
        slug: type
    }).icon;


}

