import {NOTICE, USER,UPGRADE} from "../../DATA_TYPE";

const initalState = {
    info: null,
    upgrade:false,
    notice:[]
};
export default function user(state = initalState, action) {
    switch (action.type) {
        case USER:
            return Object.assign({}, state, {info: action.payload.info});
        case UPGRADE:
            return Object.assign({}, state, {info: action.payload.upgrade});
        case NOTICE:
            return Object.assign({}, state, {notice: action.payload.notice});
        default:
            return state;

    }
}

