import _ from "lodash";
import {logOut} from "./auth";


export const ErrorMessage =  (e) =>{


    let message = '';


    if (e?.response?.data?.errors) {
        let errors = e.response.data.errors;

       //  _.map(errors, (item) => message += `${item.param} ${item.msg}`);

        errors.map((item) => message += `${item.param} ${item.msg}`);

    } else {
        message = e?.response?.data?.message ?? e.message;
    }


    if (e?.response?.data?.auth_failed){

        logOut();
        window.location.href = '/login';
    }

    return message;

}
